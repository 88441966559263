import "../../index.css"
import "../App/ProjectDescription.css"
import Header from '../Header'
import React from 'react'
// import ReactPlayer from 'react-player/youtube'

import { useLocation } from "react-router-dom";
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next'

function ProjectDescription() {

    const state = useLocation();
    const param = useParams();
    const { t } = useTranslation(); 

    console.log(state);
    console.log(param);
    const projectDescritpionJson = require(`../../Projet_Parametrage/${state.state.ProjectFolder}/Project.json`);
    const description = projectDescritpionJson.Description;
    const listServices = projectDescritpionJson.Services;
    const listImages = projectDescritpionJson.Images;

    function AddImagesOrVideos(item) {
        if (item.type === 'image') {
            // var img = document.createElement("img");
            // img.src = require(`../../Projet_Parametrage/${state.state.ProjectFolder}/${item.name}`);
            // img.className = "project_image";
            // // <img className={`project_image`} src={require(`../../Projet_Parametrage/${state.state.ProjectFolder}/${item}`)} alt="" loading="lazy" />
            // return img;
            return (<img className={`project_image`} src={require(`../../Projet_Parametrage/${state.state.ProjectFolder}/${item.name}`)} alt="" loading="lazy" />)
        }

        if (item.type === 'video') {
            // return (<iframe className={`project_video`} src={`${item.name}`} allow="autoplay"></iframe>)
            return (
                <div className="project_video_container" >
                    <div className="project_video_second_container" >
                        {/* <iframe title={`project_video`} src={`${item.name}`} width="640" height="480" allow="autoplay"></iframe> */}
                        <iframe title={`project_video`} className={`project_video`} src={`${item.name}`} allow="autoplay"></iframe>
                    </div>
                </div>)
        }
        
        else if (item.type === 'video_vertical') {
            return (
                <div className="project_video_container_vertical" >
                    <div className="project_video_second_container_vertical" >
                        <iframe title={`project_video`} className={`project_video`} src={`${item.name}`} allow="autoplay"></iframe>
                    </div>
                </div>)
        }
    };

    return (
        <div className="main">
            <div className="header">
                <Header />
            </div>
            <div className="project_wrapper" >
                <div className="project_text" >
                    {/* <img data-src="" className="project_image image_main lazyload" /> */}
                    <div className="project_text_hero" >
                        <h2 className="title med" >{state.state.Title}</h2>
                        <p className="description reg" >
                            {t(description)}
                        </p>
                    </div>
                    <div className="project_text_infos" >
                        <div className="date med" >{projectDescritpionJson.Year}</div>
                        <div className="table_activity" >
                            <div className="tr_hero" >
                                <div className="th td_service med" >Services</div>
                            </div>
                            <div className="tr" >
                                {listServices.map(item =>
                                    <div className="td td_service reg" > {t(item)} </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="project_images_wrapper" >
            {listImages.map(item =>
                // <img className={`project_image`} src={require(`../../Projet_Parametrage/${state.state.ProjectFolder}/${item.name}`)} alt="" loading="lazy" />
                AddImagesOrVideos(item)
            )}
            </div>
        </div>
    );
}

export default ProjectDescription;
