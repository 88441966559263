import "../../index.css"
import "../App/Contact.css"
import Header from '../Header'
import Footer from '../Footer'
import React from 'react'
import {Link} from 'react-router-dom'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faEnvelope} from '@fortawesome/free-solid-svg-icons';
import {faLinkedin} from "@fortawesome/free-brands-svg-icons";
import { useTranslation } from 'react-i18next'

// import {BrowserRouter, Routes, Route, Link} from 'react-router-dom'

function Contact() {
  const { t } = useTranslation();

  return (
    <div className="main">
      <Header />
      <main className="Contact">
        <div className="AboutMe_Text">
          <h1>Contact</h1>
          <p>
            {t("Description_Contact")}
          </p>
        </div>
        <div className="button_Wrapper">
          <li className="link__item">
            <Link className="footer__link" to="https://www.linkedin.com/in/louise-gauvrit-7a6413169?originalSubdomain=fr">
              <FontAwesomeIcon className="Icon_Contact" icon={faLinkedin} />
            </Link>
          </li>
          <li className='link__item'>
            <a href="mailto:louise.gauvrit@gmail.com "> <FontAwesomeIcon className="Icon_Contact" icon={faEnvelope} /></a>
          </li>
        </div>
      </main>
      <Footer />
    </div>
  );
}

export default Contact;
